import React from "react";

const BasicsInfo = () => {
  return (
    <div>
      <ul className="mt-4 text-lg">
        <h4
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-xl text-slate-800 text-center extraBold"
        >
          More Info:
        </h4>
        <li className="mt-4">
          ✅ Suitable for any beginners, college students & working professional
        </li>
        <li className="mt-4">
          <span className="font-semibold ">📆 Date : </span>
          <button className="border border-[#7620ff] bg-[#7620ff] text-white p-1 rounded-md px-2  ">
            Dec 11th - 17th
          </button>
        </li>
        <li className="mt-4">
          <span className="font-semibold ">⏰ Time : </span>
          <button className="border border-[#7620ff] bg-[#7620ff] text-white p-1 rounded-md px-2  ">
            7:30 - 8.30 p.m
          </button>
        </li>
        <li className="mt-4">
          <span className="font-semibold ">🔔 Lang : </span>
          <button className="border border-[#7620ff] bg-[#7620ff] text-white p-1 rounded-md px-2  ">
            in Tamil{" "}
          </button>
        </li>
        <li className="mt-4">🎥 Live Interactive Session</li>
        <li className="mt-4">🕹️ Recordings & notes are provided</li>
        <li className="mt-4">
          📲 Free guidance on demat account opening & mobile app demo
        </li>
        <li className="mt-4">✅ WhatsApp community for free </li>
      </ul>
    </div>
  );
};

export default BasicsInfo;
