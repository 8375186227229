import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Assets
import LogoImg from "../../assets/img/clients/flogo.png";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    // <Wrapper>
    //   <div className="darkBg">
    //     <div className="container p-10">
    //       <InnerWrapper
    //         className="flex justify-around items-center"
    //         style={{ padding: "30px 0" }}
    //       >
    //         <Link
    //           className="flexCenter animate pointer"
    //           to="home"
    //           smooth={true}
    //           offset={-80}
    //         >
    //           <Link className="pointer flexNullCenter" to="/" smooth={true}>
    //             <img className="h-14  mt-2 " src={LogoImg} alt="logo" />
    //           </Link>{" "}
    //         </Link>

    //         <div className=" ">
    //           <h4 className="text-white text-center pt-2  mt-8">Follow us👇</h4>

    //           <div className=" bg-[#3653d2] rounded-lg flex justify-between  text-white p-2 cursor-pointer mt-1">
    //             <a href="https://www.instagram.com/smartstockinvestor?igsh=MTBzaDdiNnBuY2pvZQ==">
    //               {" "}
    //               <FaInstagram className="mr-3" size={25} />
    //             </a>
    //             <a href="https://www.facebook.com/profile.php?id=61558175617995">
    //               {" "}
    //               <FaFacebook className="mr-3" size={25} />
    //             </a>
    //             <a href="https://www.linkedin.com/in/ragulravichandran/">
    //               {" "}
    //               <FaLinkedin className="mr-3" size={25} />
    //             </a>
    //             <a href="https://www.youtube.com/channel/UCFFYhoJhuOSF6QgF99mx8Fg">
    //               {" "}
    //               <FaYoutube className="mr-1" size={25} />
    //             </a>
    //           </div>
    //         </div>

    //         <div className="text-[#a2accd] cursor-pointer mt-8">
    //           <Link to="/terms&conditions">Terms & Conditions</Link>
    //           <br />
    //           <Link to="/privacypolicy">Privacy Policy</Link>
    //           <br />
    //           <Link to="/refundpolicy">Refund Policy</Link>
    //         </div>

    //         {/* <Link
    //           className="whiteColor mt-1 animate pointer font13"
    //           to="home"
    //           smooth={true}
    //           offset={-80}
    //         >
    //           Back to top ⬆
    //         </Link> */}

    //       </InnerWrapper>
    //     </div>
    //     <StyleP className="whiteColor text-center pb-2 font13">
    //           © {getCurrentYear()} -{" "}
    //           <span className="purpleColor font13">Smart Stock Investor</span>{" "}
    //           All Right Reserved
    //         </StyleP>
    //   </div>
    // </Wrapper>
    <footer class="bg-gray-900 p-10 tracking-wide">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div class="lg:flex lg:items-center">
          <img src={LogoImg} alt="logo" class="h-24" />
        </div>

        <div class="lg:flex lg:items-center">
          <ul class="flex space-x-6">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61558175617995">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-gray-300 hover:fill-white w-7 h-7"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7v-7h-2v-3h2V8.5A3.5 3.5 0 0 1 15.5 5H18v3h-2a1 1 0 0 0-1 1v2h3v3h-3v7h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/ragulravichandran/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-gray-300 hover:fill-white w-7 h-7"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M21 5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5zm-2.5 8.2v5.3h-2.79v-4.93a1.4 1.4 0 0 0-1.4-1.4c-.77 0-1.39.63-1.39 1.4v4.93h-2.79v-8.37h2.79v1.11c.48-.78 1.47-1.3 2.32-1.3 1.8 0 3.26 1.46 3.26 3.26zM6.88 8.56a1.686 1.686 0 0 0 0-3.37 1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68zm1.39 1.57v8.37H5.5v-8.37h2.77z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/smartstockinvestor/?igsh=MTBzaDdiNnBuY2pvZQ%3D%3D">
                <FaInstagram className="" fill="#d1d5db" size={25} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCFFYhoJhuOSF6QgF99mx8Fg">
                {" "}
                <FaYoutube fill="#d1d5db" size={25} />
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h4 class="text-lg font-semibold mb-6 text-white">Contact Us</h4>
          <ul class="space-y-4">
            <li>
              <a
                href="javascript:void(0)"
                class="text-gray-300 hover:text-white text-sm"
              >
                Email : office@smartstockinvestor.in
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="text-gray-300 hover:text-white text-sm"
              >
                Phone : +91 9003863517
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="text-gray-300 hover:text-white text-sm"
              >
                Address : Coimbatore, 641017
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h4 class="text-lg font-semibold mb-6 text-white">Information</h4>
          <ul class="space-y-4">
            <li>
              <Link
                to="/terms&conditions"
                class="text-gray-300 hover:text-white text-sm"
              >
                Terms &amp; Conditions
              </Link>
            </li>
            <li>
              <Link
                to="/privacypolicy"
                class="text-gray-300 hover:text-white text-sm"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/refundpolicy"
                class="text-gray-300 hover:text-white text-sm"
              >
                Refund Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <p class="text-gray-300 text-sm mt-10">
        © 2024
        <a target="_blank" class="hover:underline mx-1">
          Smart Stock Investor.
        </a>
        All Rights Reserved.
      </p>
    </footer>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    // margin: 20px 0;
  }
`;
