import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import logo from "../../../src/assets/img/clients/logo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="/" smooth={true}>
            <img className="h-24  " src={logo} alt="logo" />
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="font15 pointer">
              <NavLink
                activeclass="active"
                style={{
                  padding: "10px 15px",
                  fontFamily: "Khula, sans-serif",
                }}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="font15 pointer">
              <NavLink
                to="/coursereview"
                // activeClass="active"
                style={{
                  padding: "10px 15px",
                  fontFamily: "Khula, sans-serif",
                }}
                // spy={true}
                // smooth={true}
                // offset={-80}
              >
                Course Review
              </NavLink>
            </li>
            <li className="font15 pointer">
              <NavLink
                activeclass="active"
                style={{
                  padding: "10px 15px",
                  fontFamily: "Khula, sans-serif",
                }}
                to="/courses"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Courses
              </NavLink>
            </li>

            <li className="font15 pointer">
              <NavLink
                activeClass="active"
                style={{
                  padding: "10px 15px",
                  fontFamily: "Khula, sans-serif",
                }}
                to="/contact"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </NavLink>
            </li>
          </UlWrapper>
          <Link
            to="/courses"
            className="bg-[#0b093b] text-white  hideBtn radius8 hover:text-white  cursor-pointer"
            style={{ padding: "10px 15px" }}
          >
            <UlWrapperRight className="flexNullCenter  ">
              <li className="semiBold cursor-pointer font15 pointer flexCenter">
                Join us 🚀{" "}
              </li>
            </UlWrapperRight>
          </Link>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
